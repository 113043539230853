// color variants
@import "themes-vars.module.scss";

// third-party
@import "~react-perfect-scrollbar/dist/css/styles.css";

//-----------------------|| APEXCHART ||-----------------------//

.box_style {
  width: 600px !important;
  min-width: 0 !important;
  display: flex !important;
  justify-content: center !important;
  height: 50px !important;
  padding-top: 5px !important;
}
.style {
  font-weight: bold;
  color: red;
  padding: 0 4px 0 4px;
}
.div {
  background: white;
  font-weight: bold;
  height: auto;
  width: 500px;
  padding: 10px;
  box-shadow: inset;
  box-shadow: -1px 0px 5px 0px rgba(66, 57, 57, 0.75);
  -webkit-box-shadow: -1px 0px 5px 0px rgba(66, 57, 57, 0.75);
  -moz-box-shadow: -1px 0px 5px 0px rgba(66, 57, 57, 0.75);
}
.top-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: rgb(227, 242, 253);
}

.box_style {
  width: 600px !important;
  min-width: 0 !important;
  display: flex !important;
  justify-content: center !important;
  height: 50px !important;
  padding-top: 5px !important;
}

.two-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 16px; /* Gap between items */
  padding-bottom: 15px;
}

.top-area img {
  width: 46px;
  vertical-align: middle;
  height: 45px;
  object-fit: contain;
}

.certificate-title {
  background: rgb(227, 242, 253);
  color: #616161;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 auto;
}

.certificate-section {
  padding: 5px;
  margin-top: 10px;
  color: white;
  color: #616161;
  border-top: 2px solid rgb(0, 140, 186);
}

.name {
  text-align: center;
  font-size: 20px;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.training {
  background: rgb(0, 140, 186);
  border-radius: 5px;
  color: white;
  position: relative;
  bottom: 13px;
  text-align: center;
  margin: 20px 0px;
  padding: 2px;
}

.bottom-certificate {
  height: auto;
  margin-left: -10px;
  position: relative;
  bottom: -10px;
}

.signature-text {
  font-weight: bold;
  font-family: "Rock Salt", cursive;
  text-decoration: underline;
}

.website-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  bottom: 15px;
}

div.gallery {
  margin: 5px;
  float: left;
  cursor: pointer;
}

.gallery.selected {
  border-color: #eeeeee;
  border-style: dashed;
}

div.gallery img {
  width: 82px;
  height: 75px;
  border-radius: 100px;
}

div.desc {
  text-align: left;
}

input[type="file"] {
  opacity: 0;
  z-index: -1;
  cursor: pointer;
}
.label,
input[type="file"] {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  // padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
label {
  position: relative;
}

.lbl {
  position: relative;
  bottom: 12px;
}

.thumb_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.thumb_inner {
  display: flex;
  min-width: 0px;
  overflow: hidden;
  width: 100%;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.notification {
  background-color: #008cba;
  color: white;
  width: 300px;
}

.button_color {
  background-color: #008cba;
  color: white;
  &:hover {
    background-color: #008cba;
    color: white;
  }
}

.img_preview {
  display: flex;
  width: 100px;
  height: 100px;
  object-fit: contain;
  justify-content: center;
  align-items: center;
}

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

.css-1ifk55o-MuiStepIcon-text {
  fill: white;
}

.css-ll32jh-MuiTypography-root {
  font-size: 15px;
}

.css-fuymge-MuiTableCell-root {
  border-bottom: transparent;
}

.css-1ku04m1-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  //top: 169px !important;
  // left: 880px !important;
}

.box {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 8px 17px #eee;
  position: relative;
  background-color: #fff;
  font-family: "Manrope", sans-serif;
  background-size: 700px;
  width: 600px;
}
.rect1 {
  position: absolute;
  top: 0;
  right: 0;
  height: 200px;
  width: 200px;
  background: linear-gradient(to bottom right, #6c2f9e, #5590c8);
  border-radius: 50%;
  transform: rotate(45deg) translateY(-50%) translateX(-20%);
  z-index: -1;
}
.signature .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input {
  font-family: "Rock Salt", cursive;
  font-size: 1.1em;
}
.logo .img img {
  height: 50px;
  margin-right: 10px;
}

.img {
  border-bottom: 1px solid #1e7baa;
}
.logo .title {
  background: #999;
  font-weight: 300;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 100px;
}
.logo {
  font-size: 30px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(to bottom right, #6c2f9e, #5590c8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
}
.content {
  padding: 30px;
  display: block;
  align-items: center;
  justify-content: center;
}
.content .datename {
  // margin-bottom: 50px;
}
.content .signature {
  display: block;
}
.content .signature p {
  color: #999;
  font-size: 15px;
  border-top: 1px solid #999;
}
.content p {
  font-size: 12px;
}
.text {
  min-width: max-content;
}

.css-goc8xd-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input[multiple] {
  height: 300px;
}

//-----------------------|| PERFECT SCROLLBAR ||-----------------------//

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

//-----------------------|| ANIMATION KEYFRAMES ||-----------------------//

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}
